import ReactToPrint from 'react-to-print';
import Schema from "../../schema";
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import "./billing.css";
import Input from "../../component/inputs/input";
import * as React from "react";
import Box from "@mui/material/Box";
import BarcodeInput from "../../component/inputs/barcodeInput";
import ItemCard from "../../component/card/productCard";
import Paper from "@mui/material/Paper";
import { Button, FormControl, IconButton, InputAdornment, Menu, MenuItem, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import Modal from "../../component/modal";
import Cash from "./onlinePopup";
import Ofline from "./oflinePopup";
import { useDispatch, useSelector } from "react-redux";
import { BillingInterface } from "../../Interface/ProductsInterface";
import { useBillingStyles } from "../pagesUtils/useBillingStyles";
import { RootState, RootStateBillingProduct } from "../../Interface/BillerIdInterface";
import { ClearAll } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import { PanToolOutlined, ArrowDropDown } from "@mui/icons-material";
import { creatOrder, getCustomernamebynumber } from "./action";
import { Form, Formik } from "formik";
import Utils from "../../utility";
import Dialog from '@mui/material/Dialog';
import CustomerDetailsForm from "./CustomerDetailsForm";
import CloseIcon from '@mui/icons-material/Close';
import { clearCustomerDetails, clearInvoiceDetails } from "./reducer";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

export default function Index() {
  const [onlinePinValue, setOnlinePinValue] = React.useState<string>("");
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [buttonClicked, setButtonClicked] = React.useState(false);
  const { storeId: StoreID } = useSelector((state:any) => state.commonReducer);
  const {billerIdData} = useSelector((state: RootState) => state.loginReducer);
  const {customerName, customerNumber,customerCompany,customerGst,customerDetails} = useSelector((state:any)=> state.productsReducer)
  const [customerOpen, setCustomerOpen] = React.useState(false);
  const [customerNameData,setCustomerNameData] = React.useState("");
  const [customerPhoneData,setCustomerPhoneData] = React.useState("");
  const [splitOpen,setSplitOpen] = React.useState(false);

  const handleSplitOpen = () =>{
    setSplitOpen(true)
  }

  const handleSplitClose = () => {
    setSplitOpen(false);
  };

  const handleClickOpen = () => {
    setCustomerOpen(true);
  };

  const handleClose = () => {
    setCustomerOpen(false);
  };
  // React.useEffect(() => {
  //   setOnlinePinValue(wallet);
  //      // eslint-disable-next-line
  // }, [wallet,onlinePinValue]);

  const dispatch: any = useDispatch();
  const { pin } = useSelector((state: any) => state.productsReducer);
  const classes = useBillingStyles();
  const [barcode, setBarcode] = React.useState("");
  const [open, setOpen] = React.useState<string>("");
  const [isOnline, setIsonline] = React.useState<boolean>(false);
  const { products } = useSelector(
    (state: RootStateBillingProduct) => state.productsReducer
  );
  const [addedProduct, setAddedProduct] = React.useState<any[]>(
    []
  );
  const [grandTotal, setGrandTotal] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [cashReceive, setCashReceive] = React.useState<string | number | any>("");
  const [cashReturn, setCashReturn] = React.useState<string | number>("");
  const [discounPersentage, setDiscountPercentage] = React.useState<number>(0);
  const [discounAmount, setDiscountAmount] = React.useState<number>(0);
  const inputRef = React.useRef<any>(0)
  
  const handleChangeBarcode = (e: any) => {
    setBarcode(e.target.value);
    let filteredNewArray: any = [];
    if (e.target.value.length >= 3) {
      // eslint-disable-next-line
      const modifiedBarcode = e.target.value.replace(/^0+/, '');
          // setBarcode(modifiedBarcode);
           filtered.map((item: any) => {
            if (
              item?.barcode === modifiedBarcode
            ) {
              let updatedQuantity = 1;
              if (item.barcode.includes(modifiedBarcode)) {
                updatedQuantity = item.quantity + 1;
              }
              const newObj = {
                id: item.id,
                name: item.name,
                quantity:  updatedQuantity,
                barcode: item?.barcode,
                printedMrp: item.printedMrp,
                sellingPrice: item.sellingPrice,
                productCostPrice: item.productCostPrice,
                expiryDate: item.expiryDate,
                weight: item?.weight,
                originalQuantity: item?.quantity,
                brandName: item?.product?.brands?.brandName
              };
              filteredNewArray = [...filteredNewArray, newObj];
              setBarcode("")
              return newObj;
            }
          })
      // eslint-disable-next-line
       products.filter((item: BillingInterface) => {
        const existingProduct = filtered?.find(
          (filteredItem: BillingInterface) => filteredItem?.barcode === modifiedBarcode
        );
        if (
          item?.product?.barcode === modifiedBarcode
        ) {
          // If the product is already in the filtered array, increase its quantity
          let updatedQuantity = 1;
          if (existingProduct) {
            updatedQuantity = existingProduct.quantity + 1;
          }
          const newObj = {
            id: item.product?.id,
            name: item.product.name,
            quantity: updatedQuantity,
            barcode: item?.product?.barcode,
            printedMrp: item.printedMrp,
            sellingPrice: item.sellingPrice,
            productCostPrice: item.productCostPrice,
            expiryDate: item.expiryDate,
            weight: item?.product?.description,
            originalQuantity: item?.quantity,
            brandName: item?.product?.brands?.brandName
          };
          filteredNewArray = [...filteredNewArray, newObj];
          setBarcode("")
          return newObj;
        }else if(
        item?.product?.name.toLowerCase().replace(/ +/g, "") ===
          e.target.value.toLowerCase().replace(/ +/g, "") ||
        item?.product?.name
          .toLowerCase()
          .replace(/ +/g, "")
          ?.includes(e.target.value) === true){
            const productExists = filtered.find(
              (addedItem: any) => addedItem.barcode === item.product?.barcode
            );
            if(productExists){
              return;
            }
            const newObj = {
              id: item.product?.id,
              name: item.product.name,
              quantity: 0,
              barcode: item?.product?.barcode,
              printedMrp: item.printedMrp,
              sellingPrice: item.sellingPrice,
              productCostPrice: item.productCostPrice,
              expiryDate: item.expiryDate,
              weight: item?.product?.description,
              originalQuantity: item?.quantity,
              brandName: item?.product?.brands?.brandName
            };
            filteredNewArray = [...filteredNewArray, newObj];
          }
          return filteredNewArray
        });
        setAddedProduct([...addedProduct, ...filteredNewArray]);
    }
  };

  React.useEffect(() => {
    if (barcode.length < 3) return;

    let filteredNewArray: any = [];
    const normalizedBarcode = barcode.toLowerCase().replace(/ +/g, ''); 
    const modifiedBarcode = normalizedBarcode.replace(/^0+/, '');

    products.map((item: BillingInterface) => {
      const productName = item?.product?.name.toLowerCase().replace(/ +/g, '');
      if (item?.product?.barcode === modifiedBarcode) {
        const newObj = {
          id: item.product?.id,
          name: item.product.name,
          quantity: 1,
          barcode: item?.product?.barcode,
          printedMrp: item.printedMrp,
          sellingPrice: item.sellingPrice,
          productCostPrice: item.productCostPrice,
          expiryDate: item.expiryDate,
          weight: item?.product?.description,
          originalQuantity: item?.quantity,
          brandName: item?.product?.brands?.brandName
        };
        filteredNewArray = [...filteredNewArray, newObj];
        setBarcode("");
        return newObj;
      } else if (productName === normalizedBarcode || productName.includes(normalizedBarcode)) {
        const newObj = {
          id: item.product?.id,
          name: item.product.name,
          quantity: 0,
          barcode: item?.product?.barcode,
          printedMrp: item.printedMrp,
          sellingPrice: item.sellingPrice,
          productCostPrice: item.productCostPrice,
          expiryDate: item.expiryDate,
          weight: item?.product?.description,
          originalQuantity: item?.quantity,
          brandName: item?.product?.brands?.brandName
        };
        filteredNewArray = [...filteredNewArray, newObj];
        return newObj;
      }
      return null;
    }).filter(item => item !== null);

    if (filteredNewArray.length > 0) {
      setAddedProduct([...addedProduct, ...filteredNewArray]);
    }
  }, [products,filtered]);

  React.useEffect(()=>{
return ()=>{
  dispatch(clearCustomerDetails())
}
  },[dispatch])

  const increaseQuantity = (key: number) => {
    setApiSuccess(false); 
    const updateProduct = addedProduct.map((item: any) => {
      // eslint-disable-next-line
      if (item.id === key) {
        const newArray = { ...item, quantity: ++item.quantity };
        setBarcode("")
        return newArray;
      } else {
        setBarcode("")
        return item;
      }
    });
    setAddedProduct([...updateProduct]);
  };

  const updateProductAmount = (key:any,newAmount:any) => {
    const updateProduct = addedProduct.map((item: any) => {
      // eslint-disable-next-line
      if (item.id === key) {
        const newArray = { ...item, sellingPrice: newAmount };
        setBarcode("")
        return newArray;
      } else {
        setBarcode("")
        return item;
      }
    });
    setAddedProduct([...updateProduct]);
  };

  const decreaseQuantity = (key: number) => {
    const updateProduct = addedProduct
      .map((item: any) => {
        if (item.id === key) {
          if (item.quantity === 1) {
            return null;
          }
          const newArray = { ...item, quantity: item.quantity > 1 ? --item.quantity : 0 };
          // const newArray = { ...item, quantity: --item.quantity };
          return newArray;
        } else return item;
      })
      .filter((item: BillingInterface) => item);
    setAddedProduct([...updateProduct]);
  };

  const arr = addedProduct;

  const ids = arr?.map((o) => o.id);
  var filtered:any = arr?.filter(
    ({ id }, index) => !ids?.includes(id, index + 1)
  );

  var debug = filtered?.filter(
    (x:any) =>
      x.name.toLowerCase().replace(/\s+/g, '').includes(barcode.toLowerCase().replace(/\s+/g, '')) ||
      x["barcode"].toLowerCase().replace(/\s+/g, '').includes(barcode.toLowerCase().replace(/\s+/g, ''))
  );
  
  var cartProducts:any = filtered.map((product:any) => product.quantity>0 && product);

  var similarObjects:any = [];

  filtered.map((obj1:any) => {
    const obj2 = products.find((obj) => obj.product.id === obj1.id);
    if (obj2) {
      similarObjects.push(obj1);
    }
    return null;
  });

  var myFilterArray = cartProducts.filter(Boolean);

  const shouldScroll = filtered.length > 5;


  React.useEffect(() => {
    let total: number = 0;
    filtered.forEach((singleTotal: BillingInterface) => {
      total = total + +singleTotal.sellingPrice * singleTotal.quantity;
    });

    if (discounAmount) {
      // eslint-disable-next-line
      {
        pin?.walletBalance > 0 &&
        pin?.walletBalance !== undefined &&
        pin?.walletBalance !== "NaN"
          ? setGrandTotal(
              total + pin?.walletBalance - (total * discounPersentage) / 100
            )
          : setGrandTotal(total - (total * discounPersentage) / 100);
      }
      // setGrandTotal(total -(total * discounPersentage) / 100);
      setDiscountAmount((total * discounPersentage) / 100);
      // setGrandTotal(total - discounAmount);
    } else {
      // eslint-disable-next-line
      {
        pin?.walletBalance > 0 &&
        pin?.walletBalance !== undefined &&
        pin?.walletBalance !== "NaN" && inputRef?.current?.value
          ? setGrandTotal(total - inputRef?.current?.value)
          : setGrandTotal(total);
      }
      // setGrandTotal(total);
    }
    setTotal(total);
    // eslint-disable-next-line
  }, [addedProduct, pin?.walletBalance,filtered]);

  // const handleChangeDiscountAmount = (e: any) => {
  //   const amount = e.target.value;
  //   // eslint-disable-next-line
  //   {
  //     pin?.walletBalance > 0 &&
  //       pin?.walletBalance !== undefined &&
  //       pin?.walletBalance !== "NaN" &&
  //       setGrandTotal(total - amount - pin?.walletBalance);
  //   }

  //   if (amount > total) {
  //     setDiscountAmount(0);
  //     setDiscountPercentage(0);
  //     setGrandTotal(total);
  //   } else {
  //     setDiscountAmount(amount);
  //     setGrandTotal(total - amount);
  //     setDiscountPercentage((amount / total) * 100);
  //   }
  //   // setGrandTotal(total - amount - pin?.walletBalance);
  //   // }
  // };

  const handleChangeDiscountAmount = (e: any) => {
    // Convert the input value to a number
    const value = e.target.value;
    const amount = parseFloat(value);
  
    // Handle invalid or non-numeric inputs
    if (isNaN(amount) || amount <= 0) {
      setDiscountAmount(0);
      setDiscountPercentage(0);
      setGrandTotal(total);
      return;
    }
  
    // Ensure walletBalance is a valid number and handle it properly
    const walletBalance = parseFloat(pin?.walletBalance || '0');
    
    if (isNaN(walletBalance)) {
      setGrandTotal(total - amount);
    } else {
      setGrandTotal(total - amount - walletBalance);
    }
  
    // Logic to handle discount amounts greater than total
    if (amount > total) {
      setDiscountAmount(0);
      setDiscountPercentage(0);
      setGrandTotal(total);
    } else {
      setDiscountAmount(amount);
      setDiscountPercentage((amount / total) * 100);
    }
  };

  const handleChangeDiscountPercentage = (e: any) => {
    const value = e.target.value;
    
    // Check if the value is a valid number and is not a negative value
    const percentage = parseFloat(value);
    
    if (isNaN(percentage) || percentage < 0) {
      // Ignore invalid or negative input
      setDiscountPercentage(0);
    } else if (percentage > 100) {
      // Cap the discount percentage at 100
      setDiscountPercentage(100);
      setGrandTotal(total - (total * 100) / 100);
      setDiscountAmount(total); // Full discount at 100%
    } else {
      // Valid input between 0 and 100
      setDiscountPercentage(percentage);
      setGrandTotal(total - (total * percentage) / 100);
      setDiscountAmount((total * percentage) / 100);
    }
  };  

  const handleChnageCashReceive = (e: any) => {
    const receive = e.target.value;
    setCashReceive(receive);
    setCashReturn(receive - grandTotal);
  };

  const handleChnageCashReturn = (e: any) => {
    const returnCash = e.target.value;
    setCashReturn(returnCash);
  };

  const totalItems = filtered.reduce((acc, item) => Number(acc) + Number(item.quantity), 0);  

  const initialValues = {
    phoneNumber: "",
    customerName: "",
    gst:"",
    companyName:"",
    address:"",
    email:"",
    customerPhone:0,
    customerPhoneError:"",
  };

  // const newhandleChangePhone =(e: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
  //   if(e.target.value.length===10){
  //     setIsDisabled(false)
  //     dispatch(getCustomernamebynumber(e.target.value, setFieldValue,setIsDisabled))
  //   }
  // }

  const newhandleChangePhone = (
    e: any, 
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const phoneNumber = e.target.value;
  
    // Update the phone number in the form field
    setFieldValue('customerPhone', phoneNumber);
  
    if (phoneNumber.length === 10) {
      // Enable further actions and clear any error
      setIsDisabled(false);
      dispatch(getCustomernamebynumber(phoneNumber, setFieldValue, setIsDisabled));
      setFieldValue('customerPhoneError', ''); // Clear error
    } else {
      // Disable further actions and set an error message
      setIsDisabled(true);
      setFieldValue(
        'customerPhoneError',
        "Must be 10 digits"
      );
    }
  };  

  const filterProduct = filtered?.map((a:any)=>a.quantity>0&&a)

  const lastFinalProduct = filterProduct.filter(Boolean)

  const totalAndDiscounts = {grandTotal, discounPersentage, discounAmount}
  let test = {customerName,customerNumber,customerCompany,customerGst}

    const handleSubmit = (values:any) => {
      setCustomerNameData(values.customerName);
      setCustomerPhoneData(values.customerPhone);
    };

    const showCart2 = localStorage.getItem("cart1")
    const showCartData = localStorage.getItem("cart2")

    const [savedCartCheck, setSavedCartCheck] = React.useState(false);
    const [currentCart, setCurrentCart] = React.useState('Cart 1');
    const [apiSuccess,setApiSuccess] = React.useState(false);

    const handleChange = (event: SelectChangeEvent) => {
      const selectedCart = event.target.value;
      handleSwitchCart(selectedCart);
    };

    React.useEffect(() => {
      const storedCart1 = localStorage.getItem('cart1');
      const storedCart2 = localStorage.getItem('cart2');
  
      if (storedCart1) {
        setCurrentCart('Cart 1');
        setAddedProduct(JSON.parse(storedCart1));
      } else if (storedCart2) {
        setCurrentCart('Cart 2');
        setAddedProduct(JSON.parse(storedCart2));
      }
    }, []); 

    const testChangeCart = () => {
      if (currentCart === "Cart 1") {
        setCurrentCart("Cart 2");
        handleSwitchCart("Cart 2")
      }else if (currentCart === "Cart 2") {
        setCurrentCart("Cart 1");
        handleSwitchCart("Cart 1")
      }
    }


    const handleHoldCart = () => {
      const cartKey = currentCart === "Cart 1" ? "cart1" : "cart2"; 
      // const savedCart = localStorage.getItem(cartKey);
      // let updatedCart = savedCart ? JSON.parse(savedCart) : [];
      // myFilterArray.forEach((item) => {
      //   const isDuplicate = updatedCart.some(cartItem => cartItem.id === item.id);
      //   if (!isDuplicate) {
      //     updatedCart.push(item);
      //   }
      // });
    
      localStorage.setItem(cartKey, JSON.stringify(myFilterArray));
      Utils.showAlert(1,"Cart has been held and saved!")
      setAddedProduct(myFilterArray);
      setSavedCartCheck(!savedCartCheck);
      testChangeCart()
    };

    const handleSwitchCart = (cartName: string) => {
      setCurrentCart(cartName);
      const cartKey = cartName === "Cart 1" ? "cart1" : "cart2";
      const savedCart = localStorage.getItem(cartKey);
      const cartData = savedCart ? JSON.parse(savedCart) : [];
    // if (cartKey == "cart2" && savedCart == null) {
    //   setAddedProduct(cartData);
    // } else {
      setAddedProduct(cartData);
    // }
    };
  
    const reversedArray = [...myFilterArray].reverse();

    const invoiceHtml = useSelector((state: any) => state.productsReducer.invoiceHtml);
    const componentRef = React.useRef<HTMLDivElement>(null);
    const reactToPrintTrigger = React.useRef<any>();
  
    React.useEffect(() => {
      if (invoiceHtml && reactToPrintTrigger.current) {
        reactToPrintTrigger.current.handlePrint();
      }
    }, [invoiceHtml]);

    const handleClearInvoiceHtml = () => {
      dispatch(clearInvoiceDetails())
    };

    React.useEffect(() => {
      const storedCart2 = localStorage.getItem("cart2");
      if (storedCart2) {
        setSavedCartCheck(true);
      } else {
        setSavedCartCheck(false);
      }
    }, []);

    const [card, setCard] = React.useState<any>('');
    const [cash, setCash] = React.useState<any>('');
    const [upi, setUpi] = React.useState<any>('');
    const [error, setError] = React.useState('');
  
    const handleInputChange = (e, setter) => {
      const value = parseFloat(e.target.value) || 0;
  
      // Prevent negative values
      if (value < 0) {
        setError('Negative values are not allowed.');
        return;
      }
      
      setError('');
      setter(value);
    };
  
    const totalEntered = Number(card) + Number(cash) + Number(upi);

  return (
    <Box>
<Dialog 
  open={splitOpen}
  onClose={handleSplitClose}
  maxWidth="sm"
  fullWidth
  sx={{
    '& .MuiDialog-paper': {
      borderRadius: '12px', // Slight curve to the dialog borders
      padding: '20px', // Add padding to the dialog
      backgroundColor: '#f5f5f5', // Light background color for better contrast
    },
  }}
>
<IconButton
          aria-label="close"
          onClick={handleSplitClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

  <Typography variant="h6" sx={{ mb: 3, textAlign: 'center' }}>
    Payment Breakdown
  </Typography>

  <Box sx={{ width: '100%', maxWidth: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
    <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
      Total Amount: {Math.round(grandTotal)}
    </Typography>

    <TextField
      type="text"
      variant="outlined"
      label="Card Payment"
      size="small"
      value={card}
      onChange={(e) => handleInputChange(e, setCard)}
      InputProps={{
        placeholder: 'Enter card payment',
        sx: { borderRadius: '10px' },
      }}
      fullWidth
      sx={{ mb: 2 }}
    />

    <TextField
      type="text"
      variant="outlined"
      label="Cash Payment"
      size="small"
      value={cash}
      onChange={(e) => handleInputChange(e, setCash)}
      InputProps={{
        placeholder: 'Enter cash payment',
        sx: { borderRadius: '10px' },
      }}
      fullWidth
      sx={{ mb: 2 }}
    />

    <TextField
      type="text"
      variant="outlined"
      label="UPI Payment"
      size="small"
      value={upi}
      onChange={(e) => handleInputChange(e, setUpi)}
      InputProps={{
        placeholder: 'Enter UPI payment',
        sx: { borderRadius: '10px' },
      }}
      fullWidth
      sx={{ mb: 2 }}
    />

    {error && (
      <Typography color="error" sx={{ textAlign: 'center', mb: 2 }}>
        {error}
      </Typography>
    )}

    <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
      Total Entered: {totalEntered}
    </Typography>
    <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
        }}
      >
    <Button
    disabled={totalEntered > grandTotal || totalEntered < grandTotal}
         onClick={() => {
        if (buttonClicked) return;
        dispatch(
          creatOrder(
            test,
            lastFinalProduct,
            totalAndDiscounts,
            setOpen,
            "multiple",
            onlinePinValue,
            setAddedProduct,
            billerIdData,
            setBarcode,
            setIsDisabled,
            setDiscountPercentage,
            setDiscountAmount,
            setButtonClicked,
            StoreID,
            setCashReceive,
            setCashReturn,
            setApiSuccess,
            currentCart,
            setCurrentCart,
            testChangeCart,
            upi,
            card,
            cash,
            setUpi,
            setCard,
            setCash,
            setSplitOpen
          )
        );
      }}
      variant="contained"
          sx={{
            backgroundColor: '#FFD700',
            color: '#333',
            borderRadius: '20px',
            padding: '10px 20px',
            width: '250px',
            textTransform: 'none',
            fontSize: '16px',
            '&:hover': {
                backgroundColor: '#FFD700',
            },
          }}
        >
          BILL NOW
        </Button>
    </Box>
    {totalEntered > Math.round(grandTotal) && (
      <Typography color="error" sx={{ textAlign: 'center' }}>
        Entered amount exceeds total!
      </Typography>
    )}
  </Box>
</Dialog>
      {invoiceHtml && (
   <ReactToPrint
   content={() => componentRef.current}
   ref={reactToPrintTrigger}
   onAfterPrint={handleClearInvoiceHtml} 
 />
      )}

      {invoiceHtml && (
        <div 
        id="invoice-printable"
        ref={componentRef} dangerouslySetInnerHTML={{ __html: invoiceHtml }} />
      )}
      <Box>
        <Box sx={{ width: "65%" }}>
          <BarcodeInput
            filtered={filtered}
            value={barcode.toLowerCase()}
            onChange={handleChangeBarcode}
            name={"Billing"}
          />
        </Box>
        <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", width: "65%" }}>
            <>
            {debug.length ? (
            <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={`${classes.headerCell}`}><b>Product</b></TableCell>
                  <TableCell className={`${classes.headerCell}`}><b>Brand</b></TableCell>
                  <TableCell className={`${classes.headerCell}`}><b>Weight</b></TableCell>
                  <TableCell className={`${classes.headerCell}`}><b>Price</b></TableCell>
                  <TableCell className={`${classes.headerCell}`}><b>In Stock</b></TableCell>
                  <TableCell className={`${classes.headerCell}`}><b>Quantity</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                    {barcode!=="" && debug?.map((card: BillingInterface) => (
                      <>
                      <ItemCard
                        key={card?.id}
                        id={card?.id}
                        productName={card.name}
                        quantity={card.quantity}
                        price={card.sellingPrice}
                        sellingPrice={card.sellingPrice}
                        increaseQuantity={() => increaseQuantity(card.id)}
                        decreaseQuantity={() => decreaseQuantity(card.id)}
                        weight={card?.weight}
                        originalQuantity={card.originalQuantity}
                       updateProductAmount={updateProductAmount}
                       product={card}
                       brandName={card?.brandName}
                      />      
                      </>
                    ))}
                                    </TableBody>
                      </Table>
                      </TableContainer>
            ):(
              <></>
            )}
            </>
            <>
            {barcode==="" && reversedArray.map((card:any) => (
             <ItemCard
             key={card?.id}
             id={card?.id}
             productName={card.name}
             quantity={card.quantity}
             price={card.sellingPrice}
             sellingPrice={card.sellingPrice}
             increaseQuantity={() => increaseQuantity(card.id)}
             decreaseQuantity={() => decreaseQuantity(card.id)}
             weight={card?.weight}
             originalQuantity={card.originalQuantity}
             updateProductAmount={updateProductAmount}
             brandName={card?.brandName}
           />
      ))}
            </>
        </Box>
        {apiSuccess===false ? (
          <Paper
            sx={{
              width: 400,
              position: "fixed",
              top: 0,
              bottom: 0,
              right: 0,
              zIndex: 99,
              padding: 2,
            }}
          >
            <Box sx={{ position: "relative", height: "97vh" }}>
              {/* {filtered.length && ( */}
                <>
<div style={{
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    // padding: '10px', 
}}>
{showCart2 || showCartData ? (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="cart-select-label">Cart</InputLabel>
        <Select
          labelId="cart-select-label"
          id="cart-select"
          value={currentCart}
          label="Cart"
          onChange={handleChange}
        >
          <MenuItem value="Cart 1">
            Cart 1
          </MenuItem>
          <MenuItem value="Cart 2">
            Cart 2
          </MenuItem>
        </Select>
      </FormControl>
      ) : (
<div  
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
      onClick={() => handleSwitchCart(currentCart)}
    >
      <Typography variant="body1" style={{ fontWeight: 'bold', marginLeft: 8, marginRight: 2 }}>
        Cart 1
      </Typography>
      <ArrowDropDown />
    </div>
      )}
    <div
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
  }}
  onClick={() => {
    const cartKey = currentCart === "Cart 1" ? "cart1" : "cart2";
    localStorage.removeItem(cartKey);
    setAddedProduct([])
    dispatch(clearCustomerDetails())
  }}
>
  <ClearAll />
  <Typography variant="body1" style={{ fontWeight: 'bold', marginLeft: 8 }}>
        Clear Cart
      </Typography>
</div>

    <div 
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
  }}
  onClick={handleHoldCart}
>
<PanToolOutlined />
<Typography variant="body1" style={{ fontWeight: 'bold', marginLeft: 8 }}>
Hold Cart
</Typography>
  </div>
</div>
<Formik
  enableReinitialize
  initialValues={initialValues}
  validationSchema={Schema.offlineCashCollectPopup}
  onSubmit={(values) => {
    dispatch({
      type: Utils.ActionName.PRODUCTS,
      payload: {
        customerName: values.customerName,
        customerNumber: values.customerPhone, 
      }
    });
  }}
>
  {({
    values,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
  }) => (
    <Form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '5px',
          marginTop: '10px',
          // marginBottom: '20px',
        }}
      >
        {customerName ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: '10px',
              backgroundColor: '#DEE0E8',
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                  {customerDetails.name || customerName}
                </span>
                {" | "}
                {customerDetails.number || customerNumber}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                {customerDetails.company || customerCompany} {customerDetails.gst || customerGst && "|"} {customerDetails.gst || customerGst}
              </Typography>
            </Box>
            <Box>
              <Button
                sx={{
                  minWidth: 'auto',
                  padding: '5px',
                }}
                onClick={handleClickOpen}
              >
                <EditIcon sx={{ color: 'black', fontSize: 30 }} />
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            {/* <Box sx={{ width: '100%', maxWidth: '100%'}}> */}
              {/* <TextField
              type="number"
                variant="outlined"
                label="Customer Phone"
                size="small"
                name="customerPhone"
                onChange={(e) => {
                  handleChange(e);
                  newhandleChangePhone(e, setFieldValue);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  handleSubmit(); // Trigger submit on blur
                }}
                InputLabelProps={{ style: { color: 'black' } }}
                InputProps={{
                  placeholder: 'Enter phone number',
                  sx: {
                    borderRadius: '10px',
                  },
                }}
              /> */}
              <FormControl
    sx={{
      width: '100%', // Ensures it fits the parent container
      maxWidth: '200px', // Adjust based on your layout needs
    }}
  >
              <TextField
  type="number"
  variant="outlined"
  label="Customer Phone"
  size="small"
  name="customerPhone"
  onChange={(e) => {
    handleChange(e);
    newhandleChangePhone(e, setFieldValue);
  }}
  onBlur={(e) => {
    handleBlur(e);
    handleSubmit(); // Trigger submit on blur
  }}
  InputLabelProps={{ style: { color: 'black' } }}
  InputProps={{
    placeholder: 'Enter phone number',
    sx: {
      borderRadius: '10px',
    },
  }}
  error={Boolean(values.customerPhoneError)} // Check for error presence
  // helperText={values.customerPhoneError || ''}
/>
</FormControl>
            {/* </Box> */}
            <Box sx={{ width: '100%', maxWidth: '100%'}}>
              <TextField
              disabled={values.customerPhone?.toString().length !== 10} 
                variant="outlined"
                label="Customer Name"
                size="small"
                name="customerName"
                onChange={(e) => {
                  handleChange(e);
                  // newhandleChangePhone(e, setFieldValue);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  handleSubmit(); // Trigger submit on blur
                }}
                InputLabelProps={{ style: { color: 'black' } }}
                InputProps={{
                  placeholder: 'Enter your name',
                  sx: {
                    borderRadius: '10px',
                  },
                }}
              />
            </Box>
            {/* <Input
        // disabled={customerNumber.length < 10}
        value={customerName?customerName:values?.customerName}
        name="customerName"
        type="text"
        error={errors.customerName}
        placeholder="Customer Name"
        label="Customer Name"
        onChange={handleChange}
        handleBlur={handleBlur}
        typeOf="billing"
      /> */}
            {/* <Field
              as={TextField}
              name="customerName"
              variant="outlined"
              label="Customer Name"
              size="small"
              onChange={(e) => {
                handleChange(e);
                handleSubmit(); // Trigger submit on change
              }}
              onBlur={(e) => {
                handleBlur(e);
                handleSubmit(); // Trigger submit on blur
              }}
              placeholder="Enter customer name"
              InputLabelProps={{ style: { color: 'black' } }}
              InputProps={{ sx: { borderRadius: '10px' } }}
              error={touched.customerName && Boolean(errors.customerName)}
              helperText={touched.customerName && errors.customerName}
            /> */}

            <Typography
              sx={{
                flex: 1,
                display:"flex",
                fontSize: '15px',
                fontWeight: 'bold',
                cursor: 'pointer',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                // backgroundColor: 'black',
                padding: '8.5px 10px',
                color: 'black',
                borderRadius: '10px',
                border: '0.2px solid rgb(196,196,196)',
                justifyContent:"flex-end"
              }}
              onClick={handleClickOpen}
            >
              MORE DETAILS
            </Typography>
          </>
        )}
      </Box>
    </Form>
  )}
</Formik>
<Dialog 
open={customerOpen}
onClose={handleClose}
maxWidth="sm"
fullWidth
sx={{
  '& .MuiDialog-paper': {
    borderRadius: '12px', // Slight curve to the dialog borders
  },
}}
>
<IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
          <CustomerDetailsForm 
            customerName={customerName} 
            customerNumber={customerNumber}
             customerCompany={customerCompany}
              customerGst={customerGst}
              setOpen={setOpen}
              setCustomerOpen={setCustomerOpen}
              />
      </Dialog>
      <Box
  sx={{
    maxHeight: shouldScroll ? '50vh' : 'unset', // Match maxHeight to 50vh
    overflowY: shouldScroll ? 'auto' : 'auto', // Use 'auto' for scrollbar only when needed
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '10px',
      border: '3px solid #f1f1f1',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#bbb',
    },
    padding: '10px',
    height: '30vh', // Set height to 30vh (previously 50vh) for visible area
  }}
>
  {
    filtered?.map((item, index) => {
      if (item.quantity > 0) {
        return (
          <React.Fragment key={index}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 0',
                borderBottom: '2px dotted black', // Dotted border instead of solid divider
              }}
            >
              <Box sx={{ flex: 3 }}> {/* 60% space */}
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {item.name}
                </Typography>
                <Typography variant="body2">
                  Rs.{Math.round(item.sellingPrice)} | Barcode: {item.barcode} | GST: {item.gst}%
                </Typography>
              </Box>
              <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}> {/* 20% space */}
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  x {item.quantity}
                </Typography>
              </Box>
              <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}> {/* 20% space */}
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Rs. {parseInt(item.sellingPrice) * item.quantity}
                </Typography>
              </Box>
            </Box>
          </React.Fragment>
        );
      }
      return null;
    })}
</Box>
                </>
              {/* )} */}
              {/* <Box sx={{ position: 'fixed', bottom: 130, }}> */}
              <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    position: 'sticky', // Sticky positioning for subtotal and grand total
    bottom: 0, // Fixes the position at the bottom
    zIndex: 10, // Ensures it stays on top of other content
    background: 'white', // Adds background to avoid overlap issues
    padding: '10px', // Padding for better visual separation
  }}
>
  {/* Subtotal */}
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '2px dotted black',
      marginTop:"20px"
    }}
  >
    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
      Sub Total
    </Typography>
    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
      {totalItems} items
    </Typography>
    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
      Rs.{Math.round(total)}
    </Typography>
  </Box>

  {/* Discount Section */}
  {/* <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '2px dotted black',
    }}
  >
    <Box sx={{ flex: 1 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
        Discount
      </Typography>
    </Box>
    <Box sx={{ flex: 1, display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap' }}>
      <TextField
        variant="standard"
        name="discountPercent"
        type="text"
        placeholder="amount"
        value={Math.round(discounPersentage)}
        onChange={handleChangeDiscountPercentage}
        size="small"
        InputProps={{ disableUnderline: true }}
        sx={{
          width: '30%',
          marginLeft: '30px',
          '& .MuiInputBase-input': {
            fontWeight: 'bold',
            fontSize: '1.1rem',
            textAlign: 'center',
            padding: '0',
            lineHeight: '1.5',
          },
        }}
      />
      <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.1rem', lineHeight: '1.5' }}>
        %
      </Typography>
    </Box>

    <Box sx={{ flex: 1, justifyContent: 'flex-end', alignItems: 'center', whiteSpace: 'nowrap', display: 'flex' }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.1rem', lineHeight: '1.5' }}>
        Rs.
      </Typography>
      <TextField
        variant="standard"
        name="discountAmount"
        type="text"
        value={Math.round(discounAmount)}
        placeholder="amount"
        onChange={handleChangeDiscountAmount}
        size="small"
        InputProps={{ disableUnderline: true }}
        sx={{
          width: '50px',
          '& .MuiInputBase-input': {
            fontWeight: 'bold',
            fontSize: '1.1rem',
            textAlign: 'center',
            padding: '0',
            lineHeight: '1.5',
          },
        }}
      />
    </Box>
  </Box> */}

<Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    borderBottom: '2px dotted black',
    width: '100%',
  }}
>
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}
  >
    <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
      Discount
    </Typography>
  </Box>
  
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
    }}
  >
    <TextField
    disabled={!debug.length || !reversedArray.length}
      variant="standard"
      name="discountPercent"
      type="text"
      placeholder="amount"
      value={Math.round(discounPersentage)}
      onChange={handleChangeDiscountPercentage}
      size="small"
      InputProps={{ disableUnderline: true }}
      sx={{
        width: '60px', // Adjust width to ensure proper spacing
        marginRight: '8px',
        '& .MuiInputBase-input': {
          fontWeight: 'bold',
          fontSize: '1.1rem',
          textAlign: 'center',
          padding: '0',
          lineHeight: '1.5',
        },
      }}
    />
    <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
      %
    </Typography>
  </Box>
  
  <Box
  sx={{
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
  }}
>
  <Typography
    variant="h6"
    sx={{
      fontWeight: 'bold',
      fontSize: '1.1rem',
    }}
  >
    Rs.
  </Typography>
  <TextField
    variant="standard"
    name="discountAmount"
    type="text"
    value={Math.round(discounAmount)}
    placeholder="amount"
    onChange={handleChangeDiscountAmount}
    size="small"
    InputProps={{ disableUnderline: true }}
    sx={{
      width: '60px', // Adjust width to ensure proper spacing
      '& .MuiInputBase-input': {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        textAlign: 'right',
        padding: '0',
        lineHeight: '1.5',
        margin: 0,
      },
    }}
  />
</Box>
{/* <Box
  sx={{
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
    width: '100%'
      }}
>
  <TextField
    variant="standard" // Use "filled" variant to match the style
    name="discountAmount"
    type="text"
    value={Math.round(discounAmount)}
    placeholder="amount"
    onChange={handleChangeDiscountAmount}
    size="small"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.1rem',color:"black" }}>
            Rs.
          </Typography>
        </InputAdornment>
      ),
      disableUnderline: true, // Remove underline for a filled effect
    }}
    sx={{
      minWidth: '60px', // Minimum width, can grow based on content
      flex: '1', // Allows the field to expand as needed
      '& .MuiInputBase-input': {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        textAlign: 'center', // Align text to the end
        padding: '0',
        lineHeight: '1.5',
        margin: 0,
      },
    }}
  />
</Box> */}
</Box>

  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Typography variant="h5" style={{ fontWeight: 'bold' }}>
      Grand Total
    </Typography>
    <Typography component="div" className={classes.amount} style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
      Rs. {Math.round(grandTotal)}
    </Typography>
  </Box>
</Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '5px',
          marginTop: '10px',
          marginBottom: '20px',
        }}
      >
            <Box sx={{ width: '100%', maxWidth: '100%'}}>
              <TextField
              type="number"
                variant="outlined"
                label="Cash Received"
                size="small"
                name="cashReceived"
                onChange={handleChnageCashReceive}
                InputLabelProps={{ style: { color: 'black' } }}
                InputProps={{
                  placeholder: 'Cash Received',
                  sx: {
                    borderRadius: '10px',
                  },
                }}
              />
            </Box>
            <Box sx={{ width: '100%', maxWidth: '100%'}}>
              <TextField
                value={cashReceive ? cashReturn : ""}
                variant="outlined"
                label="Return"
                size="small"
                name="return"
                onChange={handleChnageCashReturn}
                InputLabelProps={{ 
                  shrink: !!cashReturn || cashReceive !== 0,
                  style: { color: 'black' } 
                }}
                InputProps={{
                  placeholder: 'Return',
                  sx: {
                    borderRadius: '10px',
                  },
                }}
              />
            </Box>  
      </Box>
        {/* </Box> */}
  {filtered?.map(
    (a: BillingInterface) =>
      a.quantity > 0 && (
        <>
<Box
  sx={{
    position: "absolute",
    bottom: 0,
    width: '100%',
    mt: 2,
    // p: 2,
  }}
>
  {/* Top 3 payment options */}
  <Box
  sx={{
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 1.5,
    mb: 2,
    px: 2, // Padding to ensure spacing from edges
  }}
>
  <Typography
    className={classes.cashCollectMethod}
    onClick={() => {
      if (buttonClicked) return;
      dispatch(
        creatOrder(
          test,
          lastFinalProduct,
          totalAndDiscounts,
          setOpen,
          "cash",
          onlinePinValue,
          setAddedProduct,
          billerIdData,
          setBarcode,
          setIsDisabled,
          setDiscountPercentage,
          setDiscountAmount,
          setButtonClicked,
          StoreID,
          setCashReceive,
          setCashReturn,
          setApiSuccess,
          currentCart,
          setCurrentCart,
          testChangeCart
        )
      );
    }}
  >
    Cash
  </Typography>

  <Typography
    className={classes.cashCollectMethod}
    onClick={() => {
      if (buttonClicked) return;
      dispatch(
        creatOrder(
          test,
          lastFinalProduct,
          totalAndDiscounts,
          setOpen,
          "card",
          onlinePinValue,
          setAddedProduct,
          billerIdData,
          setBarcode,
          setIsDisabled,
          setDiscountPercentage,
          setDiscountAmount,
          setButtonClicked,
          StoreID,
          setCashReceive,
          setCashReturn,
          setApiSuccess,
          currentCart,
          setCurrentCart,
          testChangeCart
        )
      );
    }}
  >
    Card
  </Typography>

  <Typography
    className={classes.cashCollectMethod}
    onClick={() => {
      if (buttonClicked) return;
      dispatch(
        creatOrder(
          test,
          lastFinalProduct,
          totalAndDiscounts,
          setOpen,
          "upi",
          onlinePinValue,
          setAddedProduct,
          billerIdData,
          setBarcode,
          setIsDisabled,
          setDiscountPercentage,
          setDiscountAmount,
          setButtonClicked,
          StoreID,
          setCashReceive,
          setCashReturn,
          setApiSuccess,
          currentCart,
          setCurrentCart,
          testChangeCart
        )
      );
    }}
  >
    UPI
  </Typography>
</Box>

  {/* Bottom 2 payment options centered */}
  <Box
   sx={{
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 1.5,
    mb: 2,
    px: 2, // Padding to ensure spacing from edges
  }}
  >
    <Typography
      className={classes.cashCollectMethod}
      onClick={() => {
        setIsonline(true);
        setOpen("online");
      }}
    >
      Online
    </Typography>

    <Typography
      className={classes.cashCollectMethod}
      onClick={() => {
        if (buttonClicked) return;
        if (customerNumber) {
          dispatch(
            creatOrder(
              test,
              lastFinalProduct,
              totalAndDiscounts,
              setOpen,
              "credit",
              onlinePinValue,
              setAddedProduct,
              billerIdData,
              setBarcode,
              setIsDisabled,
              setDiscountPercentage,
              setDiscountAmount,
              setButtonClicked,
              StoreID,
              setCashReceive,
              setCashReturn,
              setApiSuccess,
              currentCart,
              setCurrentCart,
              testChangeCart
            )
          );
        } else {
          Utils.showAlert(2, "Enter Customer Details");
        }
      }}
    >
      Credit
    </Typography>

    <Typography
      className={classes.cashCollectMethod}
      onClick={handleSplitOpen}
    >
      Split
    </Typography>
  </Box>
</Box>

        </>
      )
  )}
            </Box>
          </Paper>
        ) : (
          <></>
        )}
      </Box>
      <Modal
        header="Billing"
        children={
          isOnline ? (
            <Cash
              open={open}
              setOpen={setOpen}
              addedProduct={filtered}
              setAddedProduct={setAddedProduct}
              totalAndDiscounts={{
                grandTotal: grandTotal,
                discounPersentage,
                discounAmount,
              }}
              // barcode={barcode}
              setBarcode={setBarcode}
              setDiscountPercentage={setDiscountPercentage}
            setDiscountAmount={setDiscountAmount}
            customerName={customerName}
            customerNumber={customerNumber}
            customerCompany={customerCompany}
            customerGst={customerGst}
            />
          ) : (
            <Ofline
              open={open}
              setOpen={setOpen}
              setAddedProduct={setAddedProduct}
              addedProduct={filtered}
              totalAndDiscounts={{
                grandTotal: grandTotal,
                discounPersentage,
                discounAmount,
              }}
              setBarcode={setBarcode}
              wallet={inputRef?.current?.value}
              setDiscountPercentage={setDiscountPercentage}
              setDiscountAmount={setDiscountAmount}
              customerName={customerName}
              customerNumber={customerNumber}
              customerCompany={customerCompany}
              customerGst={customerGst}
              setCustomerOpen={setCustomerOpen}
            />
          )
        }
        handleClose={() => {
          setOpen("");
          setIsonline(false);
        }}
        open={open}
      />
    </Box>
  );
}