import { useState,useEffect, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getShiftDetails } from '../../pages/billing/action';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from '../../utility/LoadingComponent';
import moment from 'moment';
import { getBillerId } from '../../pages/login/action';
import ReactToPrint from 'react-to-print';
import { clearShiftDetails } from '../../pages/billing/reducer';
import Logo from './logo.jpeg'; // Import your logo image
import "./shift.css";

function ShiftViewApprove({selectedShiftApprovedId}:any) {
  const userName = localStorage.getItem('username');
  const dispatch: any = useDispatch();
  // const {cashOutSales,totalCashouts,returns,tnsapp,cashPending,openingCash,shiftId,startTime,totalOrders,cash,upi,swiggyZomato,credit,rtv,discard,loading} = useSelector((state:any)=> state.productsReducer)
  let navigate = useNavigate();
  const[done,setDone] = useState(false)
  const { userId } = useSelector((state:any) => state.commonReducer);
  const{shiftTestDetails,loading} = useSelector((state:any)=> state.productsReducer)
  const storeName = localStorage.getItem("storename")

  useEffect(() => {
    dispatch(getBillerId(userId,navigate));
   }, [userId, dispatch,navigate]);
   
  useEffect(()=>{
    dispatch(clearShiftDetails());
      dispatch(getShiftDetails(selectedShiftApprovedId))
      return () => {
        dispatch(clearShiftDetails());
      };
  },[dispatch,selectedShiftApprovedId])

  const formatToIST = (date: string) => {
    return moment(date).utcOffset('+05:30').format('hh:mm:ss A'); //YYYY-MM-DD
  };

  const dynamicData = {
    shiftId: shiftTestDetails.shiftId,
    storeName:storeName,
    startTime: formatToIST(shiftTestDetails.startTime),
    CreatedBy: shiftTestDetails.billerName,
    openingCash: `₹${shiftTestDetails.openingCash}`,
    closingCash: `₹${shiftTestDetails.closingCash}`,
    systemCash: `₹${Math.round(shiftTestDetails.cashPending)}`,
    numberOfCashouts: shiftTestDetails.totalCashouts,
    totalCashout: `₹${Math.round(shiftTestDetails.cashOutSales)}`,
    orders: shiftTestDetails.totalOrders,
    cashSales: `₹${Math.round(shiftTestDetails.cash)}`,
    upiSales: `₹${Math.round(shiftTestDetails.upi)}`,
    swiggyZomatoSales: `₹${Math.round(shiftTestDetails.swiggyZomato)}`,
    creditSales: `₹${Math.round(shiftTestDetails.credit)}`,
    appSales: `₹${Math.round(shiftTestDetails.tnsapp)}`,
    returns: `₹${Math.round(shiftTestDetails.returns)}`,
    discards: `₹${Math.round(shiftTestDetails.discard)}`,
    rtv: `₹${Math.round(shiftTestDetails.rtv)}`,
    ApprovedBy:shiftTestDetails.approvedBillerName,
    ApprovedAmount:`₹${Math.round(shiftTestDetails.approvedAmount)}`,
    Difference: shiftTestDetails.difference
  };

  const [openShiftModal, setOpenShiftModal] = useState(true);

  useEffect(() => {
    if (done) {
      setOpenShiftModal(true);
    }
  }, [done]);

  const componentRef = useRef<HTMLDivElement>(null); 

  if (loading || !shiftTestDetails || Object.keys(shiftTestDetails).length === 0) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Box
      sx={{
        backgroundColor: '#f4f6f8',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '20px auto',
        fontFamily: 'Roboto, sans-serif',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          mb: 3,
          color: '#333',
        }}
      >
        Hello {userName}, approve this shift
      </Typography>
        <div className="print-container" ref={componentRef}>
        <div className="print-logo">
    <img src={Logo} alt="Logo" 
    // style={{ width: '70vw', marginBottom: '20px', display: 'block',margin: '0 auto' }} 
    />
  </div>
  <div style={{marginTop:"20px"}}>
      {Object.entries(dynamicData)
        .filter(([key]) => key !== 'comments')
        .map(([label, value], index) => (
          <Box
          className="print-box-container"
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1.5,
              padding: '8px 0',
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: '#555', fontWeight: 'bold' }}
            >
              {label.replace(/([A-Z])/g, ' $1').toUpperCase()}:
            </Typography>
            <Typography variant="body1" sx={{ color: '#333' }}>
              {value}
            </Typography>
          </Box>
        ))}
        </div>
</div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
        }}
      >
        <ReactToPrint
        trigger={() =>  <Button
          sx={{
            borderRadius: '20px',
            padding: '10px 20px',
            width: '250px',
            textTransform: 'none',
            fontSize: '16px',
            marginLeft:"10px"
          }}
        variant="outlined">
        Print
    </Button> }
        content={() => componentRef.current}
      />
      </Box>
    </Box>
    </>

  );
}

export default ShiftViewApprove;